import { RunTaskAsync } from '../engine/core/taskrunner.js';
import { SubCoord3D } from '../engine/geometry/coord3d.js';
import { GetBoundingBox, IsTwoManifold } from '../engine/model/modelutils.js';
import { CalculateVolume, CalculateSurfaceArea } from '../engine/model/quantities.js';
import { Property, PropertyToString, PropertyType } from '../engine/model/property.js';
import { AddDiv, AddDomElement, ClearDomElement } from '../engine/viewer/domutils.js';
import { AddSvgIconElement } from './utils.js';
import { SidebarPanel } from './sidebarpanel.js';
import { CreateInlineColorCircle } from './utils.js';
import { GetFileName, IsUrl } from '../engine/io/fileutils.js';
import { MaterialType } from '../engine/model/material.js';
import { RGBColorToHexString } from '../engine/model/color.js';

export class SidebarInjectPanel extends SidebarPanel
{
    constructor (parentDiv)
    {
        super (parentDiv);
    }

    GetName ()
    {
        return 'Injection Sites';
    }

    GetIcon ()
    {
        return 'pin';
    }

    AddObject3DProperties (object3D)
    {
        this.Clear ();

        let table = AddDiv (this.contentDiv, 'ov_property_table');
        let boundingBox = GetBoundingBox (object3D);
        let size = SubCoord3D (boundingBox.max, boundingBox.min);

        // Panel Section: Register Location Points
        let panel = this;

        // Panel Section: Setup
        let row = this.AddPropertyGroup(table, {name: "Setup"});
        row = this.AddProperty (table, new Property (PropertyType.Text,"Needle Length"), "mediumblue");
        let input = document.createElement('input');
        input.setAttribute('id', 'needle-length-input');
        input.setAttribute('type', 'number');
        input.setAttribute('size', 10);
        input.style.width = '50px';
        input.step = 0.1;
        input.value = 15.0;
        row.appendChild(input);
        panel.needleLengthInput = input;
        input.addEventListener("change", (ev)=>
        {
            let value = ev.target.value;
            panel.injectTool.SetNeedleLength(value);
        });
        row = this.AddButtonRow(table, "Save Settings", "mediumblue", "save", (ev)=>
        {
            let state = {
                needleLength: panel.needleLengthInput.value
            }
            window.localStorage.setItem("SidebarInjectPanelSettings", JSON.stringify(state));

            panel.injectTool.SaveSettings();
        });
        {
            let state = window.localStorage.getItem("SidebarInjectPanelSettings");
            if (state)
            {
                state = JSON.parse(state);

                input.value = parseFloat(state.needleLength);
            }
        }

        // Panel Section: Register Location Points
        row = this.AddPropertyGroup(table, {name: "Register Location"});
        this.registeredPointsRow = this.AddProperty (table, new Property (PropertyType.Integer, 'Points', object3D.registeredPoints ? object3D.registeredPoints.length: 0), "mediumblue");
        row = this.AddButtonRow(table, "Step 1", "mediumblue", "add point", (ev)=>{ panel.injectTool.SetRegistrationMode(); });
        row = this.AddButtonRow(table, "Step 2", "mediumblue", "register", (ev)=>{ panel.injectTool.Register(); });
        row = this.AddButtonRow(table, "Step 3", "mediumblue", "test", (ev)=>{ panel.injectTool.ShowRegistrationTestPoint(); });
        row = this.AddButtonRow(table, "Redo", "mediumblue", "clear all", (ev)=>{ panel.injectTool.ClearRegistrationPoints(); });

        // Injection Location Panel
        row = this.AddPropertyGroup(table, {name: "Entry Point"});
        row = this.AddProperty (table, new Property (PropertyType.Integer,"Status", "unset"), "mediumblue");
        row.setValue("unset");
        this.entryPointSetUnsetLabel = row;
        row = this.AddButtonRow(table, "Do", "mediumblue", "set",
            (ev)=>{
                panel.injectTool.SetEntryPoint();
                panel.entryPointSetUnsetLabel.setValue("ready");
            });
        row = this.AddButtonRow(table, "Redo", "mediumblue", "clear",
            (ev)=>{
                panel.injectTool.ClearEntryPoint();
                panel.entryPointSetUnsetLabel.setValue("unset");
            });

        // Injections Panel
        // * injection amount
        // * add point
        //   * has distance check
        // * inject
        // * clear
        row = this.AddPropertyGroup(table, {name: "Injections"});

        // allow setting of injection amount per point
        row = this.AddProperty (table, new Property (PropertyType.Text,"Amount (uL)"), "mediumblue");
        input = document.createElement('input');
        input.setAttribute('id', 'injection-amount-input');
        input.setAttribute('type', 'number');
        input.setAttribute('size', 10);
        input.style.width = '50px';
        input.step = 0.2;
        input.value = 0.4;
        row.appendChild(input);
        panel.injectionAmountInput = input;

        row = this.AddProperty (table, new Property (PropertyType.Text,"Retract (mm)"), "mediumblue");
        input = document.createElement('input');
        input.setAttribute('id', 'retraction-amount-input');
        input.setAttribute('type', 'number');
        input.setAttribute('size', 10);
        input.style.width = '50px';
        input.step = 0.1;
        input.value = 5.0;
        row.appendChild(input);
        panel.retractAmountInput = input;

        row = this.AddButtonRow(table, "Step 1", "mediumblue", "add", (ev)=>
            {
                panel.injectTool.SetInjectionMode(panel.injectionAmountInput, panel.retractAmountInput);
            });
        row = this.AddButtonRow(table, "Step 2", "mediumblue", "inject", (ev)=>{ panel.injectTool.PerformInjections(); });
        row = this.AddButtonRow(table, "Redo", "mediumblue", "clear all", (ev)=>{ panel.injectTool.ClearInjectionPoints(); });

        if (object3D.PropertyGroupCount () > 0) {
            let customTable = AddDiv (this.contentDiv, 'ov_property_table ov_property_table_custom');
            for (let i = 0; i < object3D.PropertyGroupCount (); i++) {
                const propertyGroup = object3D.GetPropertyGroup (i);
                this.AddPropertyGroup (customTable, propertyGroup);
                for (let j = 0; j < propertyGroup.PropertyCount (); j++) {
                    const property = propertyGroup.GetProperty (j);
                    this.AddPropertyInGroup (customTable, property);
                }
            }
        }
        this.Resize ();
    }
    AddButtonRow(table, title, color, buttonTitle, click)
    {
        let row = this.AddProperty(table, new Property (PropertyType.Text, title), color);
        let button = document.createElement("button");
        button.innerText = buttonTitle;
        button.classList.add("button-15");
        button.addEventListener('click', click);
        row.appendChild(button);
        return row;
    }

    AddMaterialProperties (material)
    {
        function AddTextureMap (obj, table, name, map)
        {
            if (map === null || map.name === null) {
                return;
            }
            let fileName = GetFileName (map.name);
            obj.AddProperty (table, new Property (PropertyType.Text, name, fileName));
        }

        this.Clear ();
        let table = AddDiv (this.contentDiv, 'ov_property_table');
        let typeString = null;
        if (material.type === MaterialType.Phong) {
            typeString = 'Phong';
        } else if (material.type === MaterialType.Physical) {
            typeString = 'Physical';
        }
        this.AddProperty (table, new Property (PropertyType.Text, 'Source', material.isDefault ? 'Default' : 'Model'));
        this.AddProperty (table, new Property (PropertyType.Text, 'Type', typeString));
        if (material.vertexColors) {
            this.AddProperty (table, new Property (PropertyType.Text, 'Color', 'Vertex colors'));
        } else {
            this.AddProperty (table, new Property (PropertyType.Color, 'Color', material.color));
            if (material.type === MaterialType.Phong) {
                this.AddProperty (table, new Property (PropertyType.Color, 'Ambient', material.ambient));
                this.AddProperty (table, new Property (PropertyType.Color, 'Specular', material.specular));
            }
        }
        if (material.type === MaterialType.Physical) {
            this.AddProperty (table, new Property (PropertyType.Percent, 'Metalness', material.metalness));
            this.AddProperty (table, new Property (PropertyType.Percent, 'Roughness', material.roughness));
        }
        this.AddProperty (table, new Property (PropertyType.Percent, 'Opacity', material.opacity));
        AddTextureMap (this, table, 'Diffuse Map', material.diffuseMap);
        AddTextureMap (this, table, 'Bump Map', material.bumpMap);
        AddTextureMap (this, table, 'Normal Map', material.normalMap);
        AddTextureMap (this, table, 'Emissive Map', material.emissiveMap);
        if (material.type === MaterialType.Phong) {
            AddTextureMap (this, table, 'Specular Map', material.specularMap);
        } else if (material.type === MaterialType.Physical) {
            AddTextureMap (this, table, 'Metallic Map', material.metalnessMap);
        }
        this.Resize ();
    }

    AddPropertyGroup (table, propertyGroup)
    {
        let row = AddDiv (table, 'ov_property_table_row group', propertyGroup.name);
        row.setAttribute ('title', propertyGroup.name);
        return row;
    }

    AddProperty (table, property, color="darkgrey")
    {
        let row = AddDiv (table, 'ov_property_table_row');
        let nameColumn = AddDiv (row, 'ov_property_table_cell ov_property_table_name', property.name + ':');
        nameColumn.setAttribute ('title', property.name);

        if (property.type != PropertyType.Text)
        {
            let valueColumn = AddDiv (row, 'ov_property_table_cell ov_property_table_value');
            this.DisplayPropertyValue (property, valueColumn);
            row.valueColumn = valueColumn;
            row.setValue = function (value) {
                this.valueColumn.innerHTML = value;
            }
        }
        row.color = color;
        row.style.color = color;
        row.name = property.name;
        row.unSelect = function() {
            this.style.border = "none";
            this.style.fontWeight = "normal";
            this.style.paddingLeft = "0";
        }
        return row;
    }

    SetDefaultPinType(){
        return;
        this.injectTool.SetPinType(this.pins[0].name, this.pins[0].color);
        this.pins[0].click();
    }
    SetPinValue(name, value){
        this.pins.forEach( (element) => {
            if (element.name ==name) {
                element.setValue(value);
            }
        })
    }
    SetRegisteredPoints(points)
    {
        this.registeredPoints = points;
        this.registeredPointsRow.setValue(points.length);
    }

    AddPropertyInGroup (table, property)
    {
        let row = this.AddProperty (table, property);
        row.classList.add ('ingroup');
    }

    AddCalculatedProperty (table, name, calculateValue)
    {
        let row = AddDiv (table, 'ov_property_table_row');
        let nameColumn = AddDiv (row, 'ov_property_table_cell ov_property_table_name', name + ':');
        let valueColumn = AddDiv (row, 'ov_property_table_cell ov_property_table_value');
        nameColumn.setAttribute ('title', name);

        let calculateButton = AddDiv (valueColumn, 'ov_property_table_button', 'Calculate...');
        calculateButton.addEventListener ('click', () => {
            ClearDomElement (valueColumn);
            valueColumn.innerHTML = 'Please wait...';
            RunTaskAsync (() => {
                let propertyValue = calculateValue ();
                if (propertyValue === null) {
                    valueColumn.innerHTML = '-';
                } else {
                    this.DisplayPropertyValue (propertyValue, valueColumn);
                }
            });
        });
    }

    DisplayPropertyValue (property, targetDiv)
    {
        ClearDomElement (targetDiv);
        let valueHtml = null;
        let valueTitle = null;
        if (property.type === PropertyType.Text) {
            if (IsUrl (property.value)) {
                valueHtml = '<a target="_blank" href="' + property.value + '">' + property.value + '</a>';
                valueTitle = property.value;
            } else {
                valueHtml = PropertyToString (property);
            }
        } else if (property.type === PropertyType.Color) {
            let hexString = '#' + RGBColorToHexString (property.value);
            let colorCircle = CreateInlineColorCircle (property.value);
            targetDiv.appendChild (colorCircle);
            AddDomElement (targetDiv, 'span', null, hexString);
        } else {
            valueHtml = PropertyToString (property);
        }
        if (valueHtml !== null) {
            targetDiv.innerHTML = valueHtml;
            targetDiv.setAttribute ('title', valueTitle !== null ? valueTitle : valueHtml);
        }
    }
}
