import { BigEps, IsEqualEps, RadDeg } from '../engine/geometry/geometry.js';
import { AddDiv, ClearDomElement } from '../engine/viewer/domutils.js';
import { AddSvgIconElement, IsDarkTextNeededForColor } from './utils.js';

import * as THREE from 'three';
import { ColorComponentToFloat, RGBColor } from '../engine/model/color.js';

function GetFaceWorldNormal (intersection)
{
    let normalMatrix = new THREE.Matrix4 ();
    intersection.object.updateWorldMatrix (true, false);
    normalMatrix.extractRotation (intersection.object.matrixWorld);
    let faceNormal = intersection.face.normal.clone ();
    faceNormal.applyMatrix4 (normalMatrix);
    return faceNormal;
}

function CreateMaterial (color)
{
    return new THREE.LineBasicMaterial ({
        color : color,
        depthTest : false
    });
}

function CreateLineFromPoints (points, material)
{
    let geometry = new THREE.BufferGeometry ().setFromPoints (points);
    return new THREE.Line (geometry, material);
}

class Marker
{
    constructor (intersection, radius, color)
    {
        this.intersection = null;
        this.markerObject = new THREE.Object3D ();

        let geometry = new THREE.SphereGeometry( radius, 5, 5 );
        let material = CreateMaterial (color);
        let sphere = new THREE.Mesh( geometry, material );
        this.markerObject.add (sphere);

        this.UpdatePosition (intersection);
    }

    UpdatePosition (intersection)
    {
        this.intersection = intersection;
        this.markerObject.position.set (this.intersection.point.x, this.intersection.point.y, this.intersection.point.z);
    }

    Show (show)
    {
        this.markerObject.visible = show;
    }

    GetIntersection ()
    {
        return this.intersection;
    }

    GetObject ()
    {
        return this.markerObject;
    }
}

export class PinTool
{
    constructor (viewer, settings)
    {
        this.viewer = viewer;
        this.settings = settings;
        this.isActive = false;
        this.markers = [];
        this.tempMarker = null;

        this.panel = null;
        this.button = null;
        this.sidebar = null;
        this.pins = {};

        this.isRegistrationMode = false;
    }

    SetPinType(type, color)
    {
        this.pinType = type;
        this.pinColor = color;
        if (this.tempMarker) {
            this.tempMarker.Show (false);
            this.viewer.Render ();
            this.tempMarker = null;
        }
    }
    ClearPins(){
        let tool = this;
        this.viewer.ClearExtra ();
        this.tempMarker = null;
        if (this.pins) {
            Object.keys(this.pins).forEach( (type) => {
                tool.pins[type].length = 0;
                tool.sidebar.SetPinValue(type, 0);
            } );
        }
    }
    DownloadPins(){
        this.DownloadObjectAsJson(this.pins, "injection_sites");
    }
    DownloadObjectAsJson(exportObj, exportName){
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    SetButton (button)
    {
        this.button = button;
    }

    IsActive ()
    {
        return this.isActive;
    }

    SetActive (isActive)
    {
        if (this.isActive === isActive) {
            return;
        }
        this.isActive = isActive;
        this.button.SetSelected (isActive);
        if (this.isActive) {
            this.panel = AddDiv (document.body, 'ov_measure_panel');
            this.UpdatePanel ();
            this.Resize ();
        } else {
            this.ClearMarkers ();
            this.panel.remove ();
        }
        if (!this.toolType) {
            this.sidebar.SetDefaultPinType();
        }
    }

    Click (mouseCoordinates)
    {
        let intersection = this.viewer.GetMeshIntersectionUnderMouse (mouseCoordinates);
        if (intersection === null) {
            return;
        }

        this.AddMarker (intersection, this.pinColor);
    }

    UpdateSidebar(){
        if (this.pinType && this.pins[this.pinType])
        {
            this.sidebar.SetPinValue(this.pinType, this.pins[this.pinType].length);
        }
    }

    MouseMove (mouseCoordinates)
    {
        let intersection = this.viewer.GetMeshIntersectionUnderMouse (mouseCoordinates);
        if (intersection === null) {
            if (this.tempMarker !== null) {
                this.tempMarker.Show (false);
                this.viewer.Render ();
            }
            return;
        }
        if (!this.dummyIntersection)
        {
            this.dummyIntersection = intersection;
        }
        if (this.tempMarker === null) {
            let color = this.isRegistrationMode? "purple": this.pinColor;
            this.tempMarker = this.GenerateMarker (intersection, color);
        }
        this.tempMarker.UpdatePosition (intersection);
        this.tempMarker.Show (true);
        this.viewer.Render ();
    }

    AddMarker (intersection, color)
    {
        color = this.isRegistrationMode? "purple": color;
        let marker = this.GenerateMarker (intersection, color);

        if (this.isRegistrationMode)
        {
            grx.setRegistrationPoint(intersection.point);
        } else
        {
            if (!this.pins[this.pinType]) {
                this.pins[this.pinType] = [];
            }
            this.pins[this.pinType].push(intersection.point);
        }

        this.UpdateSidebar ();
    }

    GenerateMarker (intersection, color)
    {
        let boundingSphere = this.viewer.GetBoundingSphere ((meshUserData) => {
            return true;
        });

        let radius = boundingSphere.radius / 200.0;
        let marker = new Marker (intersection, radius, color);
        this.viewer.AddExtraObject (marker.GetObject ());
        return marker;
    }

    UpdatePanel ()
    {
        function BlendBackgroundWithPageBackground (backgroundColor)
        {
            let bodyStyle = window.getComputedStyle (document.body, null);
            let bgColors = bodyStyle.backgroundColor.match (/\d+/g);
            if (bgColors.length < 3) {
                return new RGBColor (backgroundColor.r, backgroundColor.g, backgroundColor.b);
            }
            let alpha = ColorComponentToFloat (backgroundColor.a);
            return new RGBColor (
                parseInt (bgColors[0], 10) * (1.0 - alpha) + backgroundColor.r * alpha,
                parseInt (bgColors[1], 10) * (1.0 - alpha) + backgroundColor.g * alpha,
                parseInt (bgColors[2], 10) * (1.0 - alpha) + backgroundColor.b * alpha
            );
        }

        function AddValue (panel, icon, title, value)
        {
            let svgIcon = AddSvgIconElement (panel, icon, 'left_inline');
            svgIcon.title = title;
            AddDiv (panel, 'ov_measure_value', value);
        }

        ClearDomElement (this.panel);
        if (this.settings.backgroundIsEnvMap) {
            this.panel.style.color = '#ffffff';
            this.panel.style.backgroundColor = 'rgba(0,0,0,0.5)';
        } else {
            let blendedColor = BlendBackgroundWithPageBackground (this.settings.backgroundColor);
            if (IsDarkTextNeededForColor (blendedColor)) {
                this.panel.style.color = '#000000';
            } else {
                this.panel.style.color = '#ffffff';
            }
            this.panel.style.backgroundColor = 'transparent';
        }
        if (this.markers.length === 0) {
            this.panel.innerHTML = 'Select a point.';
        } else if (this.markers.length === 1) {
            this.panel.innerHTML = 'Select another point.';
        } else {
            let calcResult = CalculateMarkerValues (this.markers[0], this.markers[1]);

            if (calcResult.pointsDistance !== null) {
                AddValue (this.panel, 'measure_distance', 'Distance of points', calcResult.pointsDistance.toFixed (3));
            }
            if (calcResult.parallelFacesDistance !== null) {
                AddValue (this.panel, 'measure_distance_parallel', 'Distance of parallel faces', calcResult.parallelFacesDistance.toFixed (3));
            }
            if (calcResult.facesAngle !== null) {
                let degreeValue = calcResult.facesAngle * RadDeg;
                AddValue (this.panel, 'measure_angle', 'Angle of faces', degreeValue.toFixed (1) + '\xB0');
            }
        }
        this.Resize ();
    }

    Resize ()
    {
        if (!this.isActive) {
            return;
        }
        let canvas = this.viewer.GetCanvas ();
        let canvasRect = canvas.getBoundingClientRect ();
        let panelRect = this.panel.getBoundingClientRect ();
        let canvasWidth = canvasRect.right - canvasRect.left;
        let panelWidth = panelRect.right - panelRect.left;
        this.panel.style.left = (canvasRect.left + (canvasWidth - panelWidth) / 2) + 'px';
        this.panel.style.top = (canvasRect.top + 10) + 'px';
    }

    ClearMarkers ()
    {
        this.viewer.ClearExtra ();
        this.markers = [];
        this.tempMarker = null;
    }

    // hardware interface
    async hardwareHome()
    {
        await grx.home();
    }
    async toggleRegistrationMode()
    {
        let pinTool = this;
        if (!this.isRegistrationMode)
        {
            // enter registration mode
            grx.joystick.activateJoystick();
            await grx.setActiveSyringe(0);
            grx.addJoystickListener((position) =>
            {
                if (pinTool.dummyIntersection)
                {
                    let vpoint = grx.R2VTransform(position);
                    if (vpoint)
                    {
                        let intersection = pinTool.dummyIntersection;
                        let color = "yellow";
                        intersection.point = vpoint;
                        if (!pinTool.physicalMarker) {
                            pinTool.physicalMarker = pinTool.GenerateMarker (intersection, color);
                        }
                        pinTool.physicalMarker.UpdatePosition(intersection);
                        pinTool.physicalMarker.Show (true);
                        pinTool.viewer.Render ();
                    }
                }
            });
        } else
        {
            grx.joystick.deactivateJoystick();
            await grx.moveToSafe();
            this.ClearMarkers();
        }
        this.isRegistrationMode = !this.isRegistrationMode;
    }
    ClearRegistrationPoints()
    {
        grx.clearRegistrationPoints();
        this.ClearMarkers();
    }
    async DoInjections()
    {
        let sbytype = { "Bone":2, "Cartilage":1, "Muscle":0 };
        if (this.pins) {
            let types = Object.keys(this.pins);
            for(let t=0; t<types.length; t++)
            {
                let type = types[t];
                let pins = this.pins[type];
                for(let p=0; p<pins.length; p++)
                {
                    let pin = pins[p];
//                    await seek(sbytype[type], pin.x, pin.y, pin.z)
//                    await inject(sbytype[type]);
                    await grx.inject(sbytype[type], pin.x, pin.y, pin.z);
                }
            }
        }
        this.ClearPins();
    }

}
